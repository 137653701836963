

const getMonthList =

    [
        { id: 1, name: "Январь" },
        { id: 2, name: "Февраль" },
        { id: 3, name: "Март" },
        { id: 4, name: "Апрель" },
        { id: 5, name: "Май" },
        { id: 6, name: "Июнь" },
        { id: 7, name: "Июль" },
        { id: 8, name: "Август" },
        { id: 9, name: "Сентабрь" },
        { id: 10, name: "Октябрь" },
        { id: 11, name: "Ноябрь" },
        { id: 12, name: "Декабрь" }
    ]

export default getMonthList



